<template>
  <div>
    <div id="emailExists">
      <p
        id="emailExistsDesc"
        class="mb-2 mt-2"
      >
        Afin de vérifier vos informations, nous vous avons adressé un email avec un code à cette même adresse. Pour avancer, merci de saisir ce code.
      </p>
      <div class="form-group">
        <VTextField
          id="code"
          v-model="verificationCode"
          class="form-control"
          name="code"
          label="Code de vérification"
          placeholder="Code de vérification"
        />
      </div>
      <p
        v-if="wrongCode"
        class="error"
      >
        Code erroné
      </p>
      <div class="text-center">
        <div
          class="btn-group .fullWidth"
        >
          <HButton
            type="button"
            color="purple"
            disabled-on-loading
            @click.prevent="correct()"
          >
            Modifier l'email
          </HButton>
          &nbsp;&nbsp;
          <HButton
            color="purple"
            type="submit"
            @click.prevent="checkCode"
          >
            Valider
          </HButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  embedded: boolean,
  email: string
}

const props = defineProps<Props>()
const emit = defineEmits(['success', 'modifyemail', 'error'])
const verificationCode = ref(null)
const wrongCode = ref<null|boolean>(null)

onMounted(() => {
  // send code directly if form is embedded (not in funnel)
  nextTick(async () => {
    if (props.embedded) {
      await sendCode()
    }
  })
})

async function sendCode () {
  await useHFetch('/contact_code_verification', {
    method: 'POST',
    body: {
      email: props.email
    }
  })
}

async function checkCode () {
  try {
    const body = {
      email: props.email,
      verification_code: verificationCode.value
    }

    const { status, error } = await useHFetch('/check_code_verification', {
      method: 'POST',
      body
    })

    if (status.value === 'success') {
      wrongCode.value = false
      emit('success')
    }

    if (error.value && error.value.statusCode === 404) {
      wrongCode.value = true
      emit('error')
    }
  } catch (err) {
    emit('error')
    console.warn(err)
  }
}

function correct () {
  emit('modifyemail')
}
</script>

<style scoped>

.btn-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.confirm {
  border: none;
  color: #fff;
  background: #73bfba;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 1.5rem 3rem;
}

.btn-outline-secondary{
    color:#1f1f1f;
}

.btn-outline-secondary:hover{
    color:#fff;
}

#confirmation p:first-child{
    margin-bottom:0;
}

label{
    font-weight:bold
}

.error{
    color: #f33;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 30px;
}

.fullWidth{
  width: 100%;
  margin-bottom: 20px;
}

.fullWidth .btn-outline-secondary{
  margin-right:10px;
  background: rgba(84 195 196 14.5%) !important;
  border-color: #73bfba;
  color:#73bfba;
}

.fullWidth .btn-primary{
  margin-left:10px;
}
</style>

<style lang="scss" scoped>
.action,
.secondary {
  padding: 0.8rem 2rem;
}
</style>
