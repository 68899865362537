<template>
  <div>
    <VForm
      role="form"
      @submit.prevent="checkCode"
    >
      <div
        v-if="state.generalError"
        class="alert alert-danger"
        v-html="state.generalError"
      />
      <p>
        Pour pouvoir continuer d'avancer ensemble, nous avons besoin de la confirmation de votre numéro de téléphone.<br><br>Un code de vérification vous a été adressé par SMS. Merci de saisir le code envoyé ci-dessous.
      </p>

      <VTextField
        v-model="state.code"
        class="mt-3 textField mb-3"
        label="Code de vérification"
        placeholder="Code de vérification"
        type="text"
      />
      <div
        v-if="state.codeError"
        class="alert alert-danger"
      >
        {{ state.codeError }}
      </div>
      <div class="text-center">
        <div
          class="btn-group"
          :class="{ fullWidth: props.embedded }"
        >
          <HButton
            type="button"
            color="purple"
            disabled-on-loading
            @click.prevent="props.embedded ? modifyNumber() : correct()"
          >
            Modifier le numéro
          </HButton>
          <HButton
            color="purple"
            type="submit"
            disabled-on-loading
          >
            Valider
          </HButton>
        </div>
      </div>
    </VForm>
  </div>
</template>

<script lang="ts" setup>
/**
 * A component to check a phone number by SMS or phone call. Relies on Foster.
 */
import { normalizedFrenchPhoneFormat } from '~/utils/phoneFormat'

interface Props {
  phone: string,
  countryCode: string
  contactId: number
  embedded: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['initialize:success', 'check:success', 'modifynumber', 'error'])
const { api } = useFeathers()

const state = reactive({
  sent: false,
  code: '',
  via: 'sms',
  codeError: '',
  generalError: '',
  iPhone: props.phone,
  iCountryCode: props.countryCode
})

onMounted(() => {
  nextTick(() => {
    if (props.embedded) {
      sendCode()
    }
  })
})

async function startPhoneVerification (contactId: number, phone: string, countryCode: string, via: string) {
  return await api.service('phone-verification').create({
    contactId,
    phone,
    countryCode,
    via
  })
}

async function checkPhoneVerification (contactId: number, phone: string, countryCode: string, code: string) {
  return await api.service('phone-verification').patch(
    contactId,
    {
      phone,
      countryCode,
      code
    }
  )
}

async function sendCode () {
  try {
    state.iPhone = normalizedFrenchPhoneFormat(props.phone)
    state.iCountryCode = props.countryCode
    state.sent = true
    const res = await startPhoneVerification(props.contactId, state.iPhone, state.iCountryCode, state.via)
    if (res.success) {
      emit('initialize:success')
      state.generalError = ''
    }
  } catch (error) {
    emit('error', error.data)
    state.generalError = 'Une erreur est survenue lors de l’envoi du code.'
  }
}

async function checkCode () {
  try {
    const res = await checkPhoneVerification(props.contactId, state.iPhone, state.iCountryCode, state.code)
    if (res.success && res.status === 'approved') {
      emit('check:success')
      state.generalError = ''
    }
  } catch (error) {
    console.log(error)
    emit('error', error.data)
    state.codeError = 'Code de vérification incorrect ou expiré.'
  }
}

function correct () {
  state.sent = false
  state.codeError = ''
  state.generalError = ''
  state.code = ''
}

function modifyNumber () {
  state.sent = false
  state.codeError = ''
  state.generalError = ''
  state.code = ''
  emit('modifynumber')
}

</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100%;
  margin-bottom: 20px;
}

.btn-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.textField {
  height: 54px;
}

:deep(.v-field) {
  border-radius: 7px !important;
  color: #E6E6EA !important;
}

.textField :deep(input) {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #0A0E34B3;
}

.textField :deep(input::placeholder) {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #0A0E34;
}

:deep(.v-field__append-inner) {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #0A0E34B3;
}

:deep(.v-field__outline) {
  --v-field-border-width: 1px;
  --v-field-border-opacity: 1;
}

:deep(.v-field--variant-outlined.v-field--focused .v-field__outline) {
  color: #ED166E !important;
}
</style>
