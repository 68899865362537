<template>
  <VLabel
    text="Téléphone"
    class="mb-2"
  />
  <div class="row">
    <div class="col-12 col-lg-7">
      <VTextField
          ref="$input"
        v-model="formattedNumber"
        placeholder="06 00 00 00 00"
        type="tel"
        autocomplete="none"
        class="custom-input"
        @input="onInputChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { parsePhoneNumber } from 'awesome-phonenumber'

// Props et événements
interface Props {
  modelValue: string | null;
  errorMessages?: any[];
}
const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'valid'])

// Références
const $input = ref<HTMLElement | null>(null)
const iti = ref<any>(null)

// Données
const countryData = ref<{ iso2: string; dialCode: string }>({
  iso2: 'fr',
  dialCode: '33'
})
const rawNumber = ref<string>(props.modelValue || '')
const formattedNumber = ref<string>(formatNumber(rawNumber.value, 'fr'))

// Watchers
watch(
  () => props.modelValue,
  () => {
    rawNumber.value = props.modelValue || ''
    formattedNumber.value = formatNumber(rawNumber.value, countryData.value.iso2)
  }
)

// Initialisation intl-tel-input
onMounted(() => {
  const input = $input.value?.$el.querySelector('input')
  if (input) {
    iti.value = intlTelInput(input, {
      utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
      initialCountry: 'auto',
      geoIpLookup: (set) => {
        fetch('https://ipapi.co/json')
          .then(res => res.json())
          .then(data => set(data.country_code))
          .catch(() => set('fr'))
      },
      separateDialCode: true,
      preferredCountries: ['fr', 'de', 'be', 'ch', 'es', 'it', 'uk', 'us'],
      showFlags: false
    })

    input.addEventListener('countrychange', updateCountryData)
  }
})

// Nettoyage
onBeforeUnmount(() => {
  const input = $input.value?.querySelector('input')
  if (input) {
    input.removeEventListener('countrychange', updateCountryData)
  }
})

// Mise à jour des données pays
function updateCountryData () {
  if (!iti.value) { return }
  countryData.value = iti.value.getSelectedCountryData() || {
    iso2: 'fr',
    dialCode: '33'
  }
  formattedNumber.value = formatNumber(rawNumber.value, countryData.value.iso2)
}

// Gestion des entrées utilisateur
function onInputChange (event: Event) {
  const value = (event.target as HTMLInputElement).value.replace(/\s+/g, '') // Supprime les espaces pour validation
  rawNumber.value = value

  formattedNumber.value = formatNumber(value, countryData.value.iso2)
  emit('update:modelValue', value)
}

// Formatage du numéro
function formatNumber (value: string, countryCode: string): string {
  const parsed = parsePhoneNumber(value, { regionCode: countryCode.toUpperCase() })
  if (parsed.valid) {
    return parsed.number?.national || value // Format national
  }
  return value // Retourne brut si invalide
}

function getCountries () {
  return window.intlTelInputGlobals.getCountryData()
}

function getCountryDataByCountryCode (countryCode: string) {
  return getCountries().find((country: any) => countryCode === country.dialCode) || { iso2: 'fr', dialCode: '33' }
}
</script>
<style lang="scss" scoped>
:deep(.iti){
  width: 100% !important; /* Forcer la largeur à 100% */
  height: 59px !important; /* Hauteur personnalisée */
  border-radius: 7px !important; /* Arrondir les coins */
}

:deep(.iti--separate-dial-code .iti__selected-flag){
  background-color: transparent !important;
}

:deep(.iti__flag-container) {
  color: #0A0E34B3 !important;
  border-right: 1px solid #e6e6ea !important;
  width: 70px;
  padding-left: 8px
}

:deep(.iti__dropdown-content--dropup) {
  color: #0A0E34B3 !important;
}

:deep(.bg-white){
  color:#e6e6ea !important;
}

:deep(.v-field) {
  border-radius: 7px !important;
}

:deep(input) {
  padding-left: 80px !important;
  width: 100% !important;
  font-family: 'Hanken Grotesk', sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  color: #0A0E34B3;
}

:deep(input::placeholder) {
  font-family: 'Hanken Grotesk', sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #0A0E34B3;
}

:deep(.v-field__append-inner) {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

:deep(.v-field__outline) {
  --v-field-border-width: 1px;
  --v-field-border-opacity: 1;
}

:deep(.v-field--variant-outlined.v-field--focused .v-field__outline) {
  color: #ed166e !important;
}
</style>
